import React, { useContext } from "react";
import { navigate } from "gatsby-link";
import { graphql } from "gatsby";
import Seo from "../../components/seo";
import Layout from "../../components/layout";
import { ReasonContext } from "../../context/reasonProvider";

import ContentWrapper from "../../components/contentWrapper";
import Button from "../../components/button";
import ReasonContent from "./reasonContent/reasonContent";
import * as s from "./styles.module.scss";

type ReasonPageProps = {
  data: any;
};

const ReasonPage = ({ data: { mdx } }: ReasonPageProps): JSX.Element => {
  const { reasonNumber, nextReasonNumber } = useContext(ReasonContext);

  /**
   *
   * @param number a number - Preferably your reason number
   * @returns a string with padded zero if below 10
   */
  const padNumber = (number: number): string => {
    return number >= 10
      ? number.toString()
      : number.toString().padStart(2, "0");
  };

  const goToNextReason = () => {
    nextReasonNumber();
    navigate(`/reason/${padNumber(reasonNumber)}`);
  };

  const sendEmail = () => {
    if (typeof window !== "undefined") {
      window.location.href = "mailto:dlewisharris@gmail.com";
    } else {
      console.error("Error: Couldn't open mail link.");
    }
  };

  return (
    <Layout background>
      <Seo
        title={`Reason Number ${mdx.frontmatter.number}`}
        description={mdx.frontmatter.title}
      />
      <ContentWrapper fillAvailableHeight>
        <div className={s.reasonTemplate}>
          <div className={s.reasonTemplate__content}>
            <ReasonContent
              title={mdx.frontmatter.title}
              number={mdx.frontmatter.number}
              body={mdx.body}
            />
          </div>
          <aside className={s.reasonTemplate__options}>
            <Button
              border
              uppercase
              ariaLabel="Go to the next reason."
              content={{
                btnText: "Hmm... Tell me more",
                onClick: goToNextReason,
              }}
            />
            <Button
              border
              uppercase
              ariaLabel="Send darren an email."
              content={{ btnText: "Okay, I'm convinced!", onClick: sendEmail }}
            />
          </aside>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default ReasonPage;

export const pageQuery = graphql`
  query ReasonQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        number
      }
    }
  }
`;
