import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { useEffect, useRef } from "react";
import Typography from "../../../components/typography";
import * as s from "./reasonContent.module.scss";

type ReasonContentProps = {
  number: number | string;
  title: string;
  body?: string & React.ReactNodeArray; // Fix this somehow... Not rendering right now
};

const ReasonContent = ({
  number,
  title,
  body,
}: ReasonContentProps): JSX.Element => {
  const reasonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const elem = reasonRef.current;
    if (elem !== null) {
      elem.classList.add(s.reasonContentVisible);
    }
  });

  return (
    <div className={s.reasonContent} ref={reasonRef}>
      <div className={s.reasonContent__number}>
        <span style={{ writingMode: "vertical-lr" }}>
          <small>Reason No.</small>
        </span>
        <span className={s.number}>{number as string}</span>
      </div>
      <div className={s.reasonContent__text}>
        <Typography variant="h1">{title}</Typography>
        {body && <MDXRenderer>{body}</MDXRenderer>}
      </div>
    </div>
  );
};

export default ReasonContent;
